<template>
    <div>
        <v-container class="main-text text-container">
            <v-row>
                <v-col>
                    <h2 class="text-center">Οδηγίες Χρήσης</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <iframe src="/assets/instructions_gr.pdf" @load="loaded">

                    </iframe>
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-col>
                    <iframe src="/isntructions/instructions_gr.html" @load="loaded">

                    </iframe>
                </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        },
        methods: {
            // loaded(obj) {
            //     obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px'
            // }
        }
    }
</script>
<style scoped>
iframe {
    border: 0px;
    width: calc(88vw - 200px);
    heigdht: 14785px;
    height: 900px;
    margin-left: 200px;
}
</style>
